import React from "react"
import Navbar from "../components/navbar"
import BackgroundShape from "../components/backgroundShape"
import Container from "../components/container"
import BlogPostCard from "../components/blogPostCard"
import Header from "../components/header"
import Head from "../components/head"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"




export default function Blog() {
    const data = useStaticQuery(graphql`
        query {
          allContentfulBlog(sort: {fields: publishedAt, order: DESC}) {
            edges {
              node {
                title
                slug
                publishedAt ( formatString:"MMM Do, YYYY" )
                image
                author {
                  name
                  image {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      `)

    return (
      <Layout navbar="transparent">
          <Head title="Stay up to date with Onport latest news and industry insights." description="Use the latest marketplace trends and industry insights to better future proof your growth."/>
           
          <BackgroundShape style={{shape: "clip", color: "", particles: true}}/>
            <Container style={{classes: "container" }}>
              <div>
                <Header content={{title: "Stay up to date", text: "Follow our blog to discover the latest trends and news regarding the online marketplace industry."}} style={{color: "text-center"}}/>
              </div>
            </Container>
          <Container style={{classes: "container" }}>
            <div className="features-grid features-grid__2-col">
              {data.allContentfulBlog.edges.map((edge) => {
                debugger
                return(
                  <BlogPostCard content={{title: edge.node.title,
                      publishedAt: edge.node.publishedAt,
                      image: edge.node.image,
                      slug: edge.node.slug,
                      authorName: edge.node.author?.name,
                      authorImage: edge.node.author?.image?.file?.url
                    }}/>
                )})
              }
            </div>
          </Container>
      </Layout>
    )
}
